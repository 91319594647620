.profile {
    display: flex;
    flex-direction: column;
    gap: 16px;

    .ant-card-head {
        min-height: 40px !important;
        padding: 0 16px !important;
    }

    .ant-card-body {
        padding: 16px !important;

        p {
            line-height: 1.4;
            font-size: 12px !important;

            &:first-child {
                font-weight: 500;
            }

            &:nth-child(2) {
                font-size: 10px !important;
            }
        }
    }

    .ant-menu-light.ant-menu-root.ant-menu-inline {
        border-inline-end: none !important;
    }
}