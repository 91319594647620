.ant-layout-footer {
    height: 64px;
    padding: 0 !important;
    background: #ffffff;

    .footer__menu {
        width: 100%;
        height: 100%;
        display: flex;
        background: green($color: #000000);

        a {
            font-size: 12px !important;
            flex: 1;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 4px;
            color: rgba(0, 0, 0, 0.45) !important;

            &.active {
                color: #1677ff !important;
            }
        }
    }
}