.ant-layout-header {
    padding: 0 16px !important;
    background: #ffffff !important;

    .header__menu {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 16px;

        .ant-input-group-addon {
            display: none !important;
        }

        .ant-input-group>.ant-input:first-child {
            border-radius: 6px !important;
        }

        .ant-form-item {
            margin-bottom: 0px !important;
        }
    }
}