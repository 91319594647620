@import './scss/header';
@import './scss/footer';
@import './scss/profile';

* {
  padding: 0;
  margin: 0;
  font-size: 13px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

main {
  // position: relative;

  .ant-float-btn {
    // position: absolute !important;
    inset-block-end: 80px !important;
  }
}

// app layout
.ant-layout {
  min-height: 100vh;
  background: #f5f5f5;
  max-width: 520px;
  margin: 0 auto;

  .ant-layout-header {
    position: sticky;
    top: 0px;
    z-index: 1;
    width: 100%;
  }

  .ant-layout-content {
    padding: 16px;
    flex: 1;
  }

  .ant-layout-footer {
    position: sticky;
    bottom: 0;
    z-index: 1;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.divider {
  height: 1px;
  background: rgba(0, 0, 0, 0.075);
  width: 100%;
}

// ellipsis text 
.ellipsis {
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3,
}

// image
img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

// ant card
.ant-card {
  border: none !important;
  padding: 0;

  .ant-card-meta {
    margin: 0 !important;

    .ant-card-meta-title {
      font-size: 15px;
      line-height: 1.25;
      margin-bottom: 4px !important;
    }

    .ant-card-meta-description {
      font-size: 12px;
      line-height: 1.25;
    }
  }
}

// cart
.cart {

  // cart item card
  .cart__item__card {
    .ant-card-body {
      width: 100%;
      display: flex;
      align-items: center;
    }
  }

  .bill__summary__info {
    margin-top: 8px;
    display: flex;
    flex-direction: column;
    font-size: 13px;
    font-weight: 500;
    line-height: 1.25;
  }
}

// cart action button
.cart__item__actions {
  display: flex;
  align-items: center;
  justify-content: center;

  div {
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

// categories
.categories {
  // display: flex;
  // flex-wrap: nowrap;
  // overflow-x: auto;
  // gap: 12px;
  // -webkit-overflow-scrolling: touch;

  // .card {
  //   flex: 0 0 auto;
  // }
  height: 300px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(75px, 1fr));
  overflow-x: auto;
  gap: 12px;

  .category__card {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 6px;

    img {
      height: 75px;
      width: 75px;
      border-radius: 50%;
    }

    span {
      text-align: center;
      text-transform: capitalize;
    }
  }

  &::-webkit-scrollbar {
    display: none;
  }
}

// home restaurant
.home__restaurant__list {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(260px, 1fr));
  overflow-x: auto;
  gap: 16px;

  .restaurant__card {
    .ant-card-body {
      padding: 16px !important;

      button {
        position: absolute;
        top: 8px;
        right: 8px;

        &.ant-btn-dangerous {
          border-color: transparent !important;
        }
      }

      .restaurant__type {
        position: absolute;
        top: -1px;
        left: 0;
        background: green;
        border-top-left-radius: 8px;
        border-bottom-right-radius: 8px;
        text-align: center;
        padding: 6px 8px;
        color: #f5f5f5;
        font-size: 10px;
        font-weight: 600;
        line-height: 1;
      }
    }

    img {
      aspect-ratio: 16 /9;
    }

    .ant-card-actions {
      p {
        &:first-child {
          font-size: 12px;
          font-weight: 600;
          color: rgba(0, 0, 0, 0.803);
        }

        &:last-child {
          font-size: 10px;
        }
      }

      div {
        display: flex;
        flex-direction: column;
        gap: 4px;
      }

      .ant-rate {
        font-size: 12px !important;
      }
    }
  }
}

.ant-tabs-tab {
  font-weight: 500;
  text-transform: uppercase;
}

// product card
.product__card {
  display: flex;
  gap: 12px;
  align-items: center;

  img {
    width: 100px;
    height: 60px;
    border-radius: 6px;
  }
}

// input 
.ant-modal-close {
  display: none !important;
}

.ant-tabs-tab {
  padding: 0 0 12px 0 !important;
}

.ant-form-item {
  margin-bottom: 16px !important;
}

// orders list
.orders__list {
  display: flex;
  flex-direction: column;
  gap: 12px;

  .order__card {
    background: #ffffff;
    border-radius: 8px;
    overflow: hidden;
    padding: 12px;
    display: flex;
    flex-direction: column;
    gap: 6px;

    .order__status {
      line-height: 1;
      border-radius: 20px;
      text-transform: uppercase;
      font-size: 8px;
      font-weight: bold;
      color: #f5f5f5;
      padding: 3px 6px;
    }

    p {
      line-height: 1.2;
      font-size: 10px;
    }
  }
}

// drawer
.ant-drawer {

  .ant-drawer-header,
  .ant-drawer-body {
    padding: 16px !important;

    .ant-card {
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.1);
    }
  }
}

// cart meta title
.ant-card-meta-title {
  text-transform: capitalize !important;
}